import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LandingComponent, LayoutComponent } from '@discoverer/app-core';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { SelectiveStrategy } from './selective-strategy.service';
import { Error404Component } from './materialEca/components/reports/shared/components/error/error-404.component';

const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    {
        path: '',
        redirectTo: '/mteca/reports/projects',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivate: [AppGuardService],
        component: LayoutComponent,
        data: {
            layout: 'compact'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: '', redirectTo: 'mteca', pathMatch: 'full' },
            {
                path: 'mteca', outlet: 'dialog', loadChildren: () => import('./materialEca/materialEca.module').then(m => m.MaterialEcaModule), canActivate: [AppGuardService]
            },
            { path: 'mteca', loadChildren: () => import('./materialEca/materialEca.module').then(m => m.MaterialEcaModule), canActivate: [AppGuardService] }
        ]
    },
    { path: '**', component: Error404Component }
];

const routerConfig: ExtraOptions = {
    preloadingStrategy: SelectiveStrategy,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
